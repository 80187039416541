function AnnualCost({assessedValue, resetSearch, resetButtonRef}) {
  if (!assessedValue?.value) {
    return (
      <></>
    )
  }

  const val = assessedValue?.value || 0;
  const cost = val * 0.00023;
  const costPerYear = cost.toLocaleString(undefined, { maximumFractionDigits:2 });
  const costPerMonth = (cost / 12).toLocaleString(undefined, { maximumFractionDigits:2 });

  return (
    <>
      <div className={"bg-light p-3 rounded-1 text-center align-middle"}>
        The new library will cost your household about ${costPerYear} per year.
        That is ${costPerMonth} per month.
      </div>
      <button className="btn btn-sm btn-outline-primary mt-2 py-0 mb-2 fs-6 fw-normal"
         type="button" onClick={resetSearch} ref={resetButtonRef}>Look up another address</button>
    </>
  )

}

export default AnnualCost;
