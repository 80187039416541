import React from 'react'
import LibraryAnnualCostCalculator from "./components/Calculator";
import './App.css';


function App() {

  return (
    <div className="App">
      <LibraryAnnualCostCalculator/>
    </div>
  );
}

export default App;
