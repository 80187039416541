import React, {useEffect, useCallback, useState, useRef} from "react";
import axios from "axios";

import { ToastContainer, toast, Zoom} from 'react-toastify';

import AddressLookup from "./AddressLookup";
import AnnualCost from "./AnnualCost";

import "react-toastify/dist/ReactToastify.css";
import "./Calculator.css"

function LibraryAnnualCostCalculator() {
  const [lookup, setLookup] = useState({});
  const [streetName, setStreetName] = useState("");
  const [streetNum, setStreetNum] = useState("");
  const [streetApt, setStreetApt] = useState("");

  const refreshLookup = () => {
    let url = '/api/lookup/'
    if (streetName?.value) {
      url += `${streetName.value}/`;
      if (streetNum?.value) {
        url += `${streetNum.value}/`;
        if (streetApt?.value) {
          url += `${streetApt.value}/`;
        }
      }
    }
    axios
      .get(url)
      .then(response => setLookup(response.data))
      .catch((err) => {
        console.log(err);
        toast.error(`Cannot fetch address data ${err}`, {autoClose: false});
      });
  } // end refreshLookup

  const handleNameChange = useCallback((newValue, _changeType) => {
    setStreetApt("");
    setStreetNum("");
    setStreetName(newValue);
  }, []);
  const handleNumChange = useCallback((newValue, _changeType) => {
    setStreetApt("");
    setStreetNum(newValue);
  }, []);
  const handleAptChange = useCallback((newValue, _changeType) => {
    setStreetApt(newValue)
  });

  const resetSearch = useCallback(() => {
    setStreetName("");
    setStreetNum("");
    setStreetApt("");
  }, []);

  useEffect(
    refreshLookup,
    [
      streetName?.value,
      streetNum?.value,
      streetApt?.value,
    ]);

  const streetNameLookupRef = useRef();
  const streetNumLookupRef = useRef();
  const streetAptLookupRef = useRef();
  const resetButtonRef = useRef();

  const setNextFocus = () => {
    if (lookup.streetName?.choices && !streetName) {
      if (lookup.streetName.choices.length > 1) {
        streetNameLookupRef.current.focus();
      }
    } else if (lookup.streetNum?.choices && !streetNum) {
      if (streetNumLookupRef.current && lookup.streetNum.choices.length > 1) {
        streetNumLookupRef.current.focus();
      }
    } else if (lookup.streetApt?.choices && !streetApt) {
      if (streetAptLookupRef.current && lookup.streetApt.choices.length > 1) {
        streetAptLookupRef.current.focus();
      } else if (resetButtonRef.current) {
        resetButtonRef.current.focus();
      }
    } else if (resetButtonRef.current) {
      resetButtonRef.current.focus();
    }
  }

  useEffect(
    setNextFocus,
    [
      lookup.streetName?.choices,
      streetName,
      lookup.streetNum?.choices,
      streetNum,
      lookup.streetApt?.choices,
      streetApt
    ]
  )

  // empty if not choices
  if (!lookup.streetName) {
    return (<></>)
  }

  return (
    <>
      <AddressLookup lookupOptions={lookup.streetName?.choices}
                     defaultMenuIsOpen={!streetName}
                     placeholder={lookup.streetName?.placeholder}
                     value={streetName}
                     onChange={handleNameChange}
                     componentRef={streetNameLookupRef}
      />

      <AddressLookup lookupOptions={lookup.streetNum?.choices}
                     defaultMenuIsOpen={streetName && !streetNum && lookup.streetNum?.choices?.length > 1}
                     placeholder={lookup.streetNum?.placeholder}
                     value={streetNum}
                     onChange={handleNumChange}
                     componentRef={streetNumLookupRef}
      />

      <AddressLookup lookupOptions={lookup.streetApt?.choices}
                     defaultMenuIsOpen={streetName && streetNum && !streetApt && lookup.streetApt?.choices?.length > 1}
                     placeholder={lookup.streetApt?.placeholder}
                     value={streetApt}
                     onChange={handleAptChange}
                     componentRef={streetAptLookupRef}
      />
      <AnnualCost assessedValue={lookup?.assessed}
                  resetSearch={resetSearch}
                  resetButtonRef={resetButtonRef}/>

      <ToastContainer
        position="bottom-left"
        autoClose={2500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        transition={Zoom}
        theme={"colored"}
      />
    </>
  )
}

export default LibraryAnnualCostCalculator;
