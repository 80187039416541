import Select from "react-select";
function AddressLookup({lookupOptions, defaultMenuIsOpen, placeholder, value, onChange, componentRef}) {
  if (!lookupOptions || lookupOptions.length === 0) {
    return (
      <></>
    )
  }

  if (!value && lookupOptions?.length === 1) {
    onChange(lookupOptions[0]);
  }

  return (
    <div className={"pb-5"}>
      <Select options={lookupOptions}
              defaultMenuIsOpen={defaultMenuIsOpen}
              placeholder={placeholder}
              value={value}
              onChange={onChange}
              openMenuOnClick={true}
              openMenuOnFocus={true}
              ref={componentRef}
      />
    </div>
  )
}

export default AddressLookup;
